import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import Footer from "../components/common/footer"
import PredavanjaHeader from "../components/sections/predavanja/predavanjaheader"
import PredavanjaDescription from "../components/sections/predavanja/predavanjadescription"


const Predavanja = () => (
  <Layout>
    <SEO title="Ponudba predavanj" />
    <PredavanjaHeader />
    <Navigation />
    <PredavanjaDescription />
    <Footer />
  </Layout>
)

export default Predavanja
