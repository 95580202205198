import React from "react"
import styled from "styled-components"
import { HeaderWrapper } from "../../global"

const PredavanjaHeader = () => {

  return (
    <BookCoverWrapper>
      <h1>
        Ponudba predavanj
      </h1>
    </BookCoverWrapper>
  )
}

export default PredavanjaHeader

const BookCoverWrapper = styled(HeaderWrapper)`
  text-align: center;
`