import React from "react"

import { Section, StyledContainer } from "../../global"

const PredavanjaDescription = () => (
  <Section>
    <StyledContainer>
      <h5>Ponudba (seznam) predavanj za župnijo, gibanja, skupine, občestva</h5>
      <h4>Izobraževalna predavanja/kateheze</h4>
      <p>Trajanje: 60 – 75 min (+ možen pogovor s poslušalci)</p>
      <ul>
        <li>
          <h5>Tema: Krščanstvo pogled na vzhodnjaške tehnike</h5>
          <ul>
            <li>Čakre in krščanstvo</li>
            <li>Joga in krščanstvo</li>
            <li>Vzhodnjaška in krščanska meditacija</li>
          </ul>
        </li>
        <li>
          <h5>Tema: Krščanski pogled na new age, alternativno, »energetsko« medicino</h5>
          <ul>
            <li>New age in krščanstvo</li>
            <li>Reiki in krščanstvo</li>
            <li>Alternativna medicina in krščanstvo</li>
            <li>Kitajska medicina in krščanstvo</li>
            <li>Homeopatija, bioenergija, ajurveda in krščanstvo</li>
          </ul>
        </li>
      </ul>
      <h4>Predavanja za duhovno in osebnostno rast</h4>
      <ul>
        <li>
          <h5>Tema: Zdravje in krščanstvo</h5>
          <ul>
            <li>Kaj lahko samo Bog ozdravi?/ Kje vsak človek potrebuje ozdravljenje? (v 2. delih)</li>
            <li>Povezanost med odpuščanjem in ozdravljenjem?/ Sta odpuščanje in zdravje med seboj povezana?</li>
            <li>Kako Bogu pripraviti prostor, da nas lahko zdravi?</li>
            <li>Kako Bog ozdravlja bolečino?/ Kako Bog zdravi notranje rane?</li>
            <li>Sveto pismo in zdravje: biblični »recepti« za doseganje in ohranjanje zdravja</li>
            <li>Molitev in zdravje (v 2. delih)</li>
            <li>Kako nas Bog želi zdraviti po zakramentih? / Kako izkušati Božjo zdravilno moč v zakramentih?</li>
            <li>Zdravilna moč molitve in zakramentov</li>
            <li>Zdravilna moč usmiljenja/ Zdravilna moč odpuščanja</li>
          </ul>
        </li>
      </ul>
      <h4>Za osebnostno in duhovno rast</h4>
      <ul>
        <li>Če je Bog, zakaj potem trpljenje? / Zakaj trpljenje, če je Bog dober?</li>
        <li>Ali nam Bog res nalaga »križe«? Kaj pomeni »nositi križ«? Kateri »križi« so lažni? Kateri so neizogibni? </li>
        <li>Človek naj ne živi samo od kruha, ampak od ...</li>
        <li>V čem je Biblija aktualna? Kaj je njena posebnost v primerjavi z drugimi knjigami?</li>
        <li>Karizme in darovi Svetega Duha</li>
        <li>Boj proti zlu</li>
        <li>Gospod, nauči nas (dobro) moliti</li>
        <li>Vera in nevera</li>
        <li>Smisel in koristnost zakramentov</li>
        <li>Smisel in koristnost molitve</li>
        <li>Kaj pomeni imeti »življenje v obilju«?/ Bog in sreča: obstaja kakšna povezava med srečo in Bogom?/ Biblični »recepti« za polnost življenja: človek naj ne živi samo od kruha ...</li>
        <li>»Pravičnež«, »moralnež«, vernik, intelektualec, teolog v srečanju z Jezusom</li>
        <li>Kaj pomeni, da nas je Jezus odrešil? Kaj »imamo« od tega že v zemeljskem življenju?</li>
        <li>
          <h5>Tema: Moški, ženska in Sveto pismo</h5>
          <ul>
            <li>Moški, ženska in Sveto pismo ali Ženska iz rebra, moški iz zemlje</li>
          </ul>
        </li>
      </ul>
      <h4>Delavnica: Kako moliti, da bi bili uslišani? (za odrasle ali mlade)</h4>
      <p>V delavnici ne želimo ljudem predstaviti molitve kot nekaj »magičnega«, ker to ni, češ, »če boš tako in tako molil, boš zagotovo uslišan«. Molitev je odnos, komunikacija z Bogom. Vsako molitev Bog sprejme in odgovori nanjo »ob svojem času« in na način, ki ga izbere sam. V ospredju delavnice je spoznati nekatere »ovire« in »pogoje«, ki jih omenjajo evangeliji, da bi bila molitev dobra in Bogu všečna. Ob tem pa tudi spoznavanje različnih oblik molitve, predvsem notranjo in premišljevalno, ki prinašata največ sadov krščanskemu življenju. Žal pa spadata med najbolj zapostavljeni med katoličani. Delavnica pomaga ljudem, da bi spoznali in izkusili koristnost notranje, osebne, premišljevalne in občestvene molitve.</p>
      <h4>Za birmance, mladinsko skupino (čas: 90 ali 120 min)</h4>
      <ul>
        <li>Krščanski pogled na jogo, horoskop, new age, alternativno medicino, horoskop, meditacijo, Harry Potterja ...</li>
        <li>Odnos med Bogom in človekom, osebno pričevanje</li>
        <li>Odnos med moškim in žensko v luči Svetega pisma</li>
        <li>O Svetem Duhu, molitvi, zakramentih, Svetem pismu</li>
      </ul>
    </StyledContainer>
  </Section>
)

export default PredavanjaDescription
